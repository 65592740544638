<template>
  <!-- DESKTOP NAVIGATION -->
  <div class="d-none d-lg-block d-md-block">
    <v-app-bar
      scroll-behavior="fully-hide elevate"
      class="NavBar w-100 menu"
      :color="appBarColor"
    >
      <template v-slot:prepend>
        <NuxtLink to="/">
          <v-avatar image="/images/log.png" size="70"></v-avatar>
        </NuxtLink>

        <h5 class="text-h5 fw-bolder text-red mt-3">
          <NuxtLink class="text-red-darken-2 font-weight-bold" to="/">
            LIFELINE</NuxtLink
          >
        </h5>
      </template>

      <v-spacer></v-spacer>

      <v-list class="d-flex bg-transparent">
        <v-list-item>
          <NuxtLink class="menu-link text-white" to="/">Home</NuxtLink>
        </v-list-item>

        <v-list-item>
          <v-menu
            open-on-hover
            v-model="servicePages"
            class="position-relative"
          >
            <template v-slot:activator="{ isActive, props }">
              <NuxtLink v-bind="props" class="menu-link">
                Services<span
                  class="mdi mdi-arrow-down-drop-circle-outline ma-2 mt-3"
                ></span>
              </NuxtLink>
            </template>
            <div class="bg-green-darken-4">
              <v-list-item>
                <NuxtLink class="menu-link" to="/tuition"
                  >Home Tuition</NuxtLink
                >
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/counselling"
                  >Counselling</NuxtLink
                >
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/consultancy"
                  >Consultancy</NuxtLink
                >
              </v-list-item>
            </div>
          </v-menu>
        </v-list-item>

        <v-list-item>
          <v-menu open-on-hover v-model="tutorPages">
            <template v-slot:activator="{ props }">
              <NuxtLink class="menu-link" v-bind="props">
                Tutor<span
                  class="mdi mdi-arrow-down-drop-circle-outline mx-2"
                ></span>
              </NuxtLink>
            </template>
            <div class="bg-green-darken-4 w-100">
              <v-list-item>
                <NuxtLink class="menu-link" to="/login">Sign In</NuxtLink>
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/apply">Apply</NuxtLink>
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/code"
                  >Code of Conduct</NuxtLink
                >
              </v-list-item>
            </div>
          </v-menu>
        </v-list-item>

        <v-list-item>
          <v-menu open-on-hover v-model="otherPages" class="position-relative">
            <template v-slot:activator="{ props }">
              <NuxtLink v-bind="props" class="menu-link">
                Pages<span
                  class="mdi mdi-arrow-down-drop-circle-outline mx-2"
                ></span>
              </NuxtLink>
            </template>
            <div class="bg-green-darken-4">
              <v-list-item>
                <NuxtLink class="menu-link" to="/blog">Blog</NuxtLink>
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/gallery">Gallery</NuxtLink>
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/about">About</NuxtLink>
              </v-list-item>
              <v-list-item>
                <NuxtLink class="menu-link" to="/jobs">Jobs</NuxtLink>
              </v-list-item>
            </div>
          </v-menu>
        </v-list-item>

        <v-list-item>
          <a class="menu-link" href="#contact">Contact</a>
        </v-list-item>
        <v-list-item>
          <NuxtLink class="menu-link" to="/faq">Faq</NuxtLink>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-btn
        @click="toggleTheme"
        variant="text"
        icon="mdi-lightbulb-night-outline"
      ></v-btn>
      <v-btn variant="outlined" size="large" color="white">
        <NuxtLink class="menu-link text-white" to="/lifeline">Admin</NuxtLink>
      </v-btn>
    </v-app-bar>
  </div>

  <!-- MOBILE NAVIGATION -->
  <v-toolbar
    scroll-behavior="elevate hide"
    class="bg-green-darken-3 d-flex d-lg-none d-md-none"
    prominent
    temporary
    density="comfortable"
    :elevation="8"
  >
    <v-toolbar-title class="text-start pt-2">
      <h5 class="text-h5 fw-bolder text-red ms-0">
        <NuxtLink class="" to="/"> LIFELINE</NuxtLink>
      </h5>
    </v-toolbar-title>

    <template v-slot:append>
      <v-btn
        @click="toggleTheme"
        variant="text"
        icon="mdi-lightbulb-night-outline"
      ></v-btn>
      <v-app-bar-nav-icon
        class="text-white"
        @click.stop="mobileNav = !mobileNav"
      ></v-app-bar-nav-icon>
    </template>
  </v-toolbar>

  <v-navigation-drawer
    v-model="mobileNav"
    :location="$vuetify.display.mobile ? 'left' : undefined"
    temporary
    class="bg-green-darken-3"
  >
    <v-list class="bg-transparent text-start">
      <v-list-item-subtitle>
        <h5 class="pa-3">Lifeline Edu. Solutions</h5>
      </v-list-item-subtitle>

      <v-divider></v-divider>

      <v-list-item prepend-icon="mdi-home">
        <NuxtLink class="menu-link text-white" to="/">Home</NuxtLink>
      </v-list-item>

      <v-list-item prepend-icon="mdi-resistor">
        <v-menu v-model="mobileServicePage" class="position-relative">
          <template v-slot:activator="{ isActive, props }">
            <NuxtLink v-bind="props" class="menu-link">
              Services<span
                class="mdi mdi-arrow-down-drop-circle-outline ma-2 mt-3"
              ></span>
            </NuxtLink>
          </template>
          <div class="bg-green-darken-4">
            <v-list-item>
              <NuxtLink class="menu-link" to="/tuition">Home Tuition</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/counselling"
                >Counselling</NuxtLink
              >
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/consultancy"
                >Consultancy</NuxtLink
              >
            </v-list-item>
          </div>
        </v-menu>
      </v-list-item>

      <v-list-item prepend-icon="mdi-account">
        <v-menu v-model="mobileTutorPage">
          <template v-slot:activator="{ props }">
            <NuxtLink class="menu-link" v-bind="props">
              Tutor<span
                class="mdi mdi-arrow-down-drop-circle-outline mx-2"
              ></span>
            </NuxtLink>
          </template>
          <div class="bg-green-darken-4 w-100">
            <v-list-item>
              <NuxtLink class="menu-link" to="/login">Sign In</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/apply">Apply</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/code">Code of Conduct</NuxtLink>
            </v-list-item>
          </div>
        </v-menu>
      </v-list-item>

      <v-list-item prepend-icon="mdi-presentation">
        <v-menu v-model="mobileOtherPages" class="position-relative">
          <template v-slot:activator="{ props }">
            <NuxtLink v-bind="props" class="menu-link">
              Pages<span
                class="mdi mdi-arrow-down-drop-circle-outline mx-2"
              ></span>
            </NuxtLink>
          </template>
          <div class="bg-green-darken-4">
            <v-list-item>
              <NuxtLink class="menu-link" to="/blog">Blog</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/gallery">Gallery</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/about">About</NuxtLink>
            </v-list-item>
            <v-list-item>
              <NuxtLink class="menu-link" to="/jobs">Jobs</NuxtLink>
            </v-list-item>
          </div>
        </v-menu>
      </v-list-item>

      <v-list-item prepend-icon="mdi-phone">
        <a class="menu-link" href="#contact">Contact</a>
      </v-list-item>
      <v-list-item prepend-icon="mdi-alert-outline">
        <NuxtLink class="menu-link" to="/faq">Faq</NuxtLink>
      </v-list-item>
      <v-divider></v-divider>
      <!-- <v-list-item>
        <NuxtLink class="bg-dark ma-3" to="/">Home</NuxtLink>
      </v-list-item> -->
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <NuxtLink class="" to="/apply">
          <v-btn append-icon="mdi mdi-account" block> Become a Tutor </v-btn>
        </NuxtLink>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { useTheme } from "vuetify";
const theme = useTheme();
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}

const drawer = ref(false);
// const darkMode = ref(false);
const isScrolled = ref(false);

function navToggle() {
  drawer.value = !drawer.value;
}

function handleScroll() {
  isScrolled.value = window.scrollY > 0; // Change the threshold as needed
}

const appBarColor = computed(() =>
  isScrolled.value ? "green-darken-4" : "transparent"
);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      mobileNav: false,
      visible: false,
      tutorPages: false,
      mobileTutorPage: false,
      mobileServicePage: false,
      mobileOtherPages: false,
      servicePages: false,
      otherPages: false,
    };
  },

  methods: {
    otherPagesToggle() {
      this.otherPages = !this.otherPages;
    },

    mobileNavToggle() {
      this.mobileNav = !this.mobileNav;
    },
  },
};
</script>

<style scoped>
/* .NavBar {
  position: absolute;
  bottom: 5%;
} */

a {
  color: #ffff;
  font-family: "Kanit", "Lora", Verdana, Geneva, Tahoma, sans-serif;
}

.logo {
  width: 5rem;
  height: auto;
  font-weight: 900;
  font-family: "merriweather", Verdana, Geneva, Tahoma, sans-serif;
}

.menu-link {
  font-size: 1.2rem;
}

.menu-link::after {
  content: "";
  height: 3px;
  width: 0;
  background: red;
  position: absolute;
  left: 20px;
  bottom: -2px;
  transition: ease-in-out 0.3s;
}

.menu-link:hover::after {
  width: 60%;
  margin: auto auto;
}

.menu {
  position: relative;
}

@media only screen and (max-width: 620px) {
  .logo {
    display: none;
  }
}
</style>
